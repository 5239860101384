import React from 'react';

import PageHeader from 'components/shared/PageHeader';

import ContactForm from 'components/pages/contact/ContactForm';
import SEO from 'components/shared/SEO';

const Contact = ({ location }) => {
   const headerText = [
      'Wypróbuj LabControl i ',
      <br />,
      <strong>wprowadź cyfrowe porządki</strong>,
   ];

   return (
      <>
         <SEO title="Wypróbuj" />
         <PageHeader headerText={headerText}>
            <p>
               Dostosuj LabControl do swojej firmy i korzystaj przez 2 tygodnie
               za darmo.
            </p>
         </PageHeader>

         <ContactForm selectedPackage={location.state?.package} />
      </>
   );
};

export default Contact;
