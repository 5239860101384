import { useFormik } from 'formik';
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import routes from 'data/globals/routes';
import {
   CheckboxContainer,
   CheckboxLabel,
   ContactInfo,
   FieldContainer,
   Form,
   Group,
   Input,
   Label,
   selectStyles,
   StyledContainer,
   Submit,
   Textarea,
   Warning,
} from './ContactForm.styled';
import Select from 'react-select';
import axios from 'axios';

const options = [
   { value: 'Student', label: 'Student' },
   { value: 'Test&Go', label: 'Test&Go' },
   { value: 'Rocket', label: 'Rocket' },
];

const ContactForm = ({ selectedPackage }) => {
   const [dataProcessing, setDataProcessing] = useState(false);
   const [error, setError] = useState(null);
   const errorMessages = {
      required: 'To pole jest obowiązkowe',
      invalidEmail: 'Błędny adres email',
   };

   const dataProcessingInfo = {
      unactive: 'Wyrażam zgodę na przetwarzanie moich danych osobowych...',
      active:
         'Poinformowano mnie, iż Lab Control sp. z o.o. z siedzibą w Gdyni przetwarza podane dane osobowe w celu nawiązania lub ukształtowania stosunku prawnego zgodnie z art. 18 ust 1 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2002 r. Nr 144 poz. 104). Szczegółowe zasady przetwarzania danych osobowych określa Polityka prywatności. Zostałem poinformowany, że mam prawo wglądu, poprawiania oraz żądania usunięcia moich danych osobowych w dowolnej chwili.',
   };

   const handleDataProcess = (e) => {
      e.preventDefault();
      setDataProcessing(true);
   };

   const validate = (values) => {
      const errors = {};
      if (!formik.values.name) {
         errors.name = errorMessages.required;
      }

      if (!formik.values.email) {
         errors.email = errorMessages.required;
      } else if (
         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formik.values.email)
      ) {
         errors.email = errorMessages.invalidEmail;
      }

      if (!formik.values.company) {
         errors.company = errorMessages.required;
      }

      if (!formik.values.checkbox) {
         errors.checkbox = errorMessages.required;
      }

      return errors;
   };

   const formik = useFormik({
      initialValues: {
         name: '',
         email: '',
         phone: '',
         company: '',
         message: '',
         bundle: '',
         checkbox: false,
      },

      onSubmit: (values) => {
         const { checkbox, ...formData } = values;
         setError(null);
         axios
            .post('/mailer', formData)
            .then(() => {
               navigate(routes.confirmation);
            })
            .catch(() =>
               setError('Coś poszło nie tak, spróbuj ponownie później!'),
            );
      },

      validate,
   });

   const { getFieldProps, setFieldValue } = formik;

   return (
      <StyledContainer>
         <Form onSubmit={formik.handleSubmit} novalidate={true}>
            <Label>
               Imię i nazwisko
               <Input
                  type="text"
                  placeholder="Jan kowalski"
                  className={
                     formik.errors['name'] && formik.touched['name']
                        ? 'warning'
                        : null
                  }
                  {...getFieldProps('name')}
               />
               {formik.touched['name'] && (
                  <Warning>{formik.errors['name']}</Warning>
               )}
            </Label>

            <Label>
               Adres email
               <Input
                  type="email"
                  placeholder="Kowalski@poczta.pl"
                  className={
                     formik.errors['email'] && formik.touched['email']
                        ? 'warning'
                        : null
                  }
                  {...getFieldProps('email')}
               />
               {formik.touched['email'] && (
                  <Warning>{formik.errors['email']}</Warning>
               )}
            </Label>

            <Label>
               Nazwa firmy
               <Input
                  type="text"
                  placeholder="Wpisz tutaj"
                  className={
                     formik.errors['company'] && formik.touched['company']
                        ? 'warning'
                        : null
                  }
                  {...getFieldProps('company')}
               />
               {formik.touched['company'] && (
                  <Warning>{formik.errors['company']}</Warning>
               )}
            </Label>

            <Label>
               Telefon <span>(opcjonalne)</span>
               <Input
                  type="text"
                  placeholder="123 456 789"
                  {...getFieldProps('phone')}
               />
            </Label>

            <Label>
               Pakiet <span>(opcjonalne)</span>
               <Select
                  as="select"
                  isClearable
                  defaultValue={
                     selectedPackage
                        ? {
                             value: selectedPackage,
                             label: selectedPackage,
                          }
                        : null
                  }
                  placeholder="Wybierz pakiet który Cię interesuje"
                  styles={selectStyles}
                  options={options}
                  data-select={formik.values.bundle}
                  onChange={(selectedOption) =>
                     setFieldValue('bundle', selectedOption?.value)
                  }
               />
            </Label>

            <Label>
               Chciałbyś o coś zapytać? <span>(opcjonalne)</span>
               <Textarea
                  as="textarea"
                  placeholder="Wpisz tutaj"
                  {...getFieldProps('message')}
               />
            </Label>

            <CheckboxContainer>
               <FieldContainer>
                  <Input
                     type="checkbox"
                     id="checkbox"
                     {...getFieldProps('checkbox')}
                  />

                  <CheckboxLabel htmlFor="checkbox">
                     <>
                        {dataProcessing
                           ? dataProcessingInfo.active
                           : dataProcessingInfo.unactive}
                        {dataProcessing ? null : (
                           <button
                              aria-expanded={dataProcessing}
                              onClick={handleDataProcess}
                           >
                              Czytaj dalej
                           </button>
                        )}
                     </>
                  </CheckboxLabel>
               </FieldContainer>
               {formik.touched['checkbox'] && formik.errors['checkbox'] && (
                  <Warning>{formik.errors['checkbox']}</Warning>
               )}
            </CheckboxContainer>
            {error && <Warning>{error}</Warning>}
            <Submit type="submit">Wyślij prośbę o demo</Submit>
         </Form>

         <ContactInfo>
            <Group>
               <h4>
                  <strong>LabControl</strong>
               </h4>
               <address>
                  Ul. Abrahama 1A
                  <br />
                  80-307 GDAŃSK
                  <br />
               </address>
            </Group>

            <Group>
               <h4>
                  <strong>Kontakt</strong>
               </h4>
               <address>
                  <a href="mailto:demo@labcontrolsystem.com">
                     demo@labcontrolsystem.com
                  </a>
               </address>
            </Group>
         </ContactInfo>
      </StyledContainer>
   );
};

export default ContactForm;
