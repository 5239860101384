import Button from 'components/shared/Button/Button.styled';
import Container from 'components/shared/Container';
import styled from 'styled-components';
import { breakpoints, colors } from 'variables';

export const selectStyles = {
   container: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
      fontSize: '1.6rem',
      fontWeight: 400,
      color: 'black',
   }),
   placeholder: (styles) => ({
      ...styles,
      fontWeight: 400,
      fontSize: '1.6rem',
   }),
   control: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
      height: '50px',
      marginTop: '5px',
      boxShadow: 'none',
      borderColor: state.isFocused
         ? `${colors.brand}`
         : `${colors.greyMap.get(300)}`,
   }),
   input: (styles) => ({
      ...styles,
      cursor: 'pointer',
      fontSize: '1.6rem',
      fontWeight: 400,
   }),
   menuList: (styles, state) => ({
      ...styles,
      padding: '0px 0',
   }),

   option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
      fontSize: '1.6rem',
      fontWeight: 400,
      backgroundColor: state.isSelected && colors.brand,
      border: state.isFocused && `1px solid  ${colors.greyMap.get(300)}`,
      borderRadius: '3px',
      color: state.isSelected && 'white',
      ':hover': {
         backgroundColor: colors.greyMap.get(300),
         color: 'black',
      },
   }),
};

export const StyledContainer = styled(Container)`
   flex-direction: column;
   margin-top: 70px;

   ${breakpoints.lg`
      padding: 0 105px;
      flex-direction: row;
   `};
`;

export const Form = styled.form`
   display: flex;
   flex-direction: column;
   width: 100%;
   flex-grow: 3;
   align-items: flex-start;

   ${breakpoints.lg` padding-right: 50px; `};
`;

export const Input = styled.input`
   display: block;
   outline: 0;
   border: 1px solid ${colors.greyMap.get(300)};
   margin-top: 5px;
   padding: 15px 10px;
   width: 100%;
   color: ${colors.black};
   font-size: 1.6rem;
   border-radius: 7px;

   &::placeholder {
      color: ${colors.greyMap.get(500)};
      letter-spacing: 0.5px;
   }

   &:focus {
      border-color: ${colors.brand};
   }
   &.warning {
      border-color: ${colors.red};
   }

   &[type='checkbox'] {
      width: 18px;
      min-width: 18px;
      height: 18px;
      display: inline;
      margin: 0;
      margin-right: 10px;
      align-self: flex-start;
      cursor: pointer;
   }
`;

export const Label = styled.label`
   font-size: 1.4rem;
   font-weight: bold;
   letter-spacing: 0.2px;
   margin-top: 40px;
   width: 100%;

   &:first-of-type {
      margin-top: 0;
   }

   span {
      color: ${colors.greyMap.get(500)};
   }
`;

export const CheckboxContainer = styled.div`
   margin-top: 10px;
   display: flex;
   flex-direction: column;

   button {
      cursor: pointer;
      background: none;
      border: none;
      color: ${colors.brand};
      margin-left: 5px;
      font-size: 1.4rem;
   }
`;

export const FieldContainer = styled.div`
   display: flex;
`;

export const CheckboxLabel = styled(Label)`
   font-weight: 400;
   margin: 0;
   display: inline;
   width: fit-content;
   text-align: justify;
   line-height: 20px;
`;

export const Textarea = styled(Input)`
   min-height: 150px;
   resize: vertical;
`;

export const Submit = styled(Button)`
   cursor: pointer;
   color: ${colors.white};
   background: ${colors.brand};
   margin-top: 20px;
   align-self: center;
   width: 100%;

   ${breakpoints.lg`
      margin-top: 40px;
   `};
   :hover {
      color: ${colors.brand};
      background: ${colors.white};
   }
`;

export const ContactInfo = styled.div`
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   margin-top: 70px;
   justify-content: space-between;

   ${breakpoints.lg`
      justify-content: flex-start;
      flex-direction: column;
      margin-top: 0px;
      padding-left: 50px;
   `};
`;

export const Group = styled.div`
   margin-top: 30px;
   text-align: center;
   address {
      margin-top: 7px;
      line-height: 3rem;
      font-style: normal;
      font-size: 1.6rem;
   }

   ${breakpoints.lg`
   text-align: left;
      font-size: initial;
      margin-top: 0;
      &:last-of-type {
         margin-top: 70px;
      }
   `};
`;

export const Warning = styled.p`
   font-weight: 300;
   color: ${colors.red};
   font-size: 1.2rem;
   margin: 0;
   line-height: 2.6rem;
`;
